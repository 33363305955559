import axiosInstance from "@/utils/axiosInstance"
import { BrazeToken } from "@/utils/BrazeConfig"
import { FaroToken } from "@/utils/FaroConfig"
import { MixpanelToken } from "@/utils/MixpanelConfig"
import { queryOptions } from "@tanstack/react-query"

export type AppConfigs = {
  mixpanelToken: MixpanelToken
  brazeToken: BrazeToken
  faroToken: FaroToken
  isEnableChallenge: boolean
  isEnableTrackingDebug: boolean
  isEnableMAExchange: boolean
  isEnableMACoupon: boolean
  version: string
  assetUrl: string
  mediaUrl: string
}

const fetchAppConfigs = async () => {
  try {
    const response = await axiosInstance.get<AppConfigs>("/app-configs")
    return {
      ...response?.data,
      version: response.headers["x-api-version"] as string
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const appConfigsQueryOptions = queryOptions({
  queryKey: ["appConfigs"],
  queryFn: fetchAppConfigs,
  refetchOnWindowFocus: false
})
