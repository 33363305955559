import * as braze from "@braze/web-sdk"

export type BrazeToken = {
  appId: string
  restSDK: string
}

export const initBraze = (
  { appId, restSDK }: BrazeToken,
  isEnableTrackingDebug: boolean
) => {
  braze.initialize(appId, {
    baseUrl: restSDK,
    enableLogging: isEnableTrackingDebug
  })
  return braze
}

export default braze
