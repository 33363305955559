import { Outlet, createFileRoute, useLocation } from "@tanstack/react-router"
import CommonLayout from "./_common-layout/-components/CommonLayout"
import UnderMA from "./_main-layout/-components/UnderMA"
import useAppConfigStore from "@/stores/useAppConfigStore"
import { useMemo } from "react"

export const Route = createFileRoute("/_common-layout")({
  component: LayoutComponent
})

function LayoutComponent() {
  const { pathname } = useLocation()
  const { isEnableMAExchange, isEnableMACoupon } = useAppConfigStore()

  const showMA = useMemo(() => {
    const isAtExchangePage = pathname.includes("exchange")
    const isAtCouponPage = pathname.includes("coupon")
    return (
      (isAtExchangePage && isEnableMAExchange) || (isAtCouponPage && isEnableMACoupon)
    )
  }, [pathname, isEnableMAExchange, isEnableMACoupon])

  return (
    <CommonLayout>
      {showMA && <UnderMA />}
      {!showMA && <Outlet />}
    </CommonLayout>
  )
}
