/// <reference types="vite-plugin-svgr/client" />

import { useMemo } from "react"
import { useMatchRoute, useNavigate } from "@tanstack/react-router"
import { useTranslation } from "react-i18next"

//routes
import { Route as homeRoute } from "@/routes/_main-layout/dashboard/index"
import { Route as exchangeRoute } from "@/routes/_main-layout/exchange/index"
import { Route as couponRoute } from "@/routes/_main-layout/coupon/index"
import { Route as challengeRoute } from "@/routes/_main-layout/challenge/index"
// import { Route as bootsRoute } from '@/routes/_main-layout/boost/index'

//components
import { Button } from "@/components/ui/button"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  TooltipArrow
} from "@/components/ui/tooltip"

//assets
import IcHome from "@/assets/icons/tabs/home.svg?react"
import IcExchange from "@/assets/icons/tabs/exchange.svg?react"
import IcCoupon from "@/assets/icons/tabs/coupon.svg?react"
import IcChallenge from "@/assets/icons/tabs/boots.svg?react"
// import IcBoost from '@/assets/icons/tabs/boots.svg?react'

//APIs
import { trackTMNCoinClickButton } from "@/api/tracking.api"

//stores
import useAppConfigStore from "@/stores/useAppConfigStore"

import { Tab } from "../coupon/route.lazy"

const ACTIVE_COLOR = "#FF8724"
const DEFAULT_COLOR = "#878B96"

type MenuButtonProps = {
  label: string
  isActive?: boolean
  defaultIcon: () => JSX.Element
  activeIcon: () => JSX.Element
  handleClick?: () => void
  [key: string]: any
}

const MenuButton = ({
  label,
  isActive = false,
  defaultIcon,
  activeIcon,
  handleClick,
  ...props
}: MenuButtonProps) =>
  useMemo(
    () => (
      <Button
        {...props}
        variant="link"
        className="basis-1/3 pt-[9px] pb-[9px] h-[61px] hover:no-underline"
        onClick={() => {
          if (handleClick) handleClick()
        }}
        onContextMenu={e => e.preventDefault()}
      >
        {
          <div className="flex flex-col justify-center items-center text-center">
            {isActive ? activeIcon() : defaultIcon()}
            <span
              className={isActive ? "button-small" : "label"}
              style={{ color: isActive ? ACTIVE_COLOR : DEFAULT_COLOR }}
            >
              {label}
            </span>
          </div>
        }
      </Button>
    ),
    [label, isActive, defaultIcon, activeIcon]
  )

const BottomNav = () => {
  const { t } = useTranslation(["translation"])
  const matchRoute = useMatchRoute()
  const navigate = useNavigate()
  const { isEnableChallenge, user } = useAppConfigStore()
  const isActiveDashboard = matchRoute({ to: homeRoute.to })
  const isActiveExchange = matchRoute({ to: exchangeRoute.to })
  const isActiveCoupon = matchRoute({ to: couponRoute.to })
  const isActiveChallenge = matchRoute({ to: challengeRoute.to })

  const isShowMKPTooltip = user?.metadata?.showMKPTooltip ?? true
  return (
    <div
      data-testid="bottom-nav"
      className="w-full flex border-t border-gray-200 bg-white fixed bottom-0"
    >
      <MenuButton
        data-testid="dashboard-menu-button"
        className="flex-1"
        label={t("home")}
        isActive={!!isActiveDashboard}
        defaultIcon={() => <IcHome style={{ color: DEFAULT_COLOR }} />}
        activeIcon={() => <IcHome style={{ color: ACTIVE_COLOR }} />}
        handleClick={() => {
          navigate({ to: homeRoute.to })
          trackTMNCoinClickButton({ buttonName: "Menu home" })
        }}
      />

      <TooltipProvider>
        <Tooltip open={isShowMKPTooltip}>
          <TooltipTrigger className="border-none flex-1">
            <MenuButton
              asChild
              data-testid="exchange-menu-button"
              label={t("redeem")}
              isActive={!!isActiveExchange}
              defaultIcon={() => <IcExchange style={{ color: DEFAULT_COLOR }} />}
              activeIcon={() => <IcExchange style={{ color: ACTIVE_COLOR }} />}
              handleClick={() => {
                navigate({ to: exchangeRoute.to })
                trackTMNCoinClickButton({ buttonName: "Menu redeem point" })
              }}
            />
          </TooltipTrigger>
          <TooltipContent className="bg-tmn-sky-blue-tint-20 border-none">
            <p className="button-small text-white">{t("exchangeTooltip")}</p>
            <TooltipArrow className="fill-tmn-sky-blue-tint-20" />
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <MenuButton
        data-testid="coupons-menu-button"
        className="flex-1"
        search={{ type: "received" }}
        label={t("myCoupons")}
        isActive={!!isActiveCoupon}
        defaultIcon={() => <IcCoupon style={{ color: DEFAULT_COLOR }} />}
        activeIcon={() => <IcCoupon style={{ color: ACTIVE_COLOR }} />}
        handleClick={() => {
          navigate({ to: couponRoute.to, search: { type: Tab.received } })
          trackTMNCoinClickButton({ buttonName: "Menu my coupon" })
        }}
      />

      {isEnableChallenge && (
        <MenuButton
          data-testid="challenge-menu-button"
          className="flex-1"
          label={t("challenge")}
          isActive={!!isActiveChallenge}
          defaultIcon={() => <IcChallenge style={{ color: DEFAULT_COLOR }} />}
          activeIcon={() => <IcChallenge style={{ color: ACTIVE_COLOR }} />}
          handleClick={() => {
            navigate({ to: challengeRoute.to })
          }}
        />
      )}
    </div>
  )
}

export default BottomNav
